import React from 'react';

import Layout from "../layout/index";

import SEO from "../components/seo/index";

import Mapinitiative from "../components/map-initiative/mapinitiative";

// import PropTypes from "prop-types";

// markup
const Mapinitiativelist = ({ location }) => {
  const propData = location.state;
  const dataValue =
    propData && propData.viewData !== undefined ? propData.viewData : null;
  const donationData =
    propData && propData.props !== undefined ? propData.props : null;

  return (
    <>
      <SEO title="Forests By Heartfulness | Initiative list" />
      <Layout>
        <section id="map-initiatives">
          <Mapinitiative data={dataValue} donationData={donationData} />
        </section>
      </Layout>
    </>
  );
};

// Mapinitiativelist.propTypes = {
//   location: PropTypes.objectOf(PropTypes.object()).isRequired,
// };

export default Mapinitiativelist;

