import React, { useEffect } from "react";

import { Link, navigate } from "gatsby";

import "assets/styles/pages/mapinitiative.scss";

import Carousel from "react-multi-carousel";

import "react-multi-carousel/lib/styles.css";

import Googlemaps from "../../components/map-initiative/googlemaps";

import { mapinitiativeData } from "assets/data/mapinitiative";

import { customcardData } from "assets/data/mapinitiative";

import { truncate } from "lodash";
import moment from "moment";
import { doLogout } from "components/Auth";
import { toast, ToastContainer } from "react-toastify";
import { OverlayTrigger, Popover } from "react-bootstrap";

import infoIcon from "assets/images/icons/info-icon.png";
// import PropTypes from "prop-types";

export default function Mapinitiative(props) {
  // const propsData = props.data;
  const { data, donationData } = props;
  const dataValues =
    data && data.type === "trackNow" ? data.plantationTrackingDetails[0] : data;

  const projectData =
    data && data.type === "trackNow"
      ? data.mappedProject[0]
      : donationData?.data?.mappedProject?.[0];

  const responsive = {
    desktop: {
      breakpoint: {
        max: 30000,
        min: 320,
      },
      items: 1,
    },
  };
  const ButtonGroup = () =>
    // { next, previous }
    {
      return (
        <div className="carousel-button-group">
          {/* <div className='navigations'>
          <button onClick={() => previous()} className='me-3'>
            <i className='icon icon-arrow-left-short'></i>
          </button>
          <button onClick={() => next()}>
            <i className='icon icon-arrow-right-short'></i>
          </button>
        </div> */}
        </div>
      );
    };

  const popover = (
    <Popover id="popover-basic">
      <Popover.Header as="h3">Species Details</Popover.Header>
      <Popover.Body>
        <strong>Species Name</strong>
        <div className="pad-btm">
          {dataValues && dataValues.species
            ? dataValues.species.speciesName
            : ""}
        </div>
        <strong>Scientific Name</strong>
        <div className="pad-btm">
          {dataValues && dataValues.species
            ? dataValues.species.scientificName
            : ""}
        </div>
        <strong>Common Uses</strong>
        <div className="pad-btm">
          {dataValues && dataValues.species
            ? dataValues.species.commonUses
            : ""}
        </div>
        <strong>Natural Habitat</strong>
        <div className="pad-btm">
          {dataValues && dataValues.species
            ? dataValues.species.naturalHabitat
            : ""}
        </div>
      </Popover.Body>
    </Popover>
  );
  useEffect(() => {
    if (dataValues === null) {
      toast.error("Authentication failed");
      setTimeout(function () {
        doLogout();
        navigate("/");
      }, 3000);
    }
  }, []);

  return (
    <>
      <div className="back-button-wrapper">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <Link
                className="btn btn-secondary fhb-btn top-button-wrapper"
                to="/trackaSapling"
              >
                Back
              </Link>
            </div>
          </div>
        </div>
      </div>
      {dataValues !== null ? (
        <div className="map-page">
          <Googlemaps mapValues={props} />
          <div className="container">
            <div className="row">
              {mapinitiativeData &&
                mapinitiativeData.map((item) => {
                  return (
                    <div
                      className="card custom-cards col-md-3 col-sm-6 max-height-ttrack"
                      key={item.id}
                    >
                      <div className="card-body1 h-300">
                        <h5 className="card-title title-items">
                          Your {dataValues && dataValues.species.speciesName}{" "}
                          Tree
                        </h5>
                        {
                          <h6 className="cloudy dot-line">
                            {dataValues &&
                            dataValues.growthStatus &&
                            dataValues.growthStatus.attributeMap
                              ? dataValues.growthStatus.attributeMap.height
                              : 0}{" "}
                            cm
                          </h6>
                        }

                        <img
                          data-src={item.img}
                          className="lazyload cent-items"
                          alt={item.alt}
                        />
                      </div>
                      <div className="card-body dark-black">
                        {/* <h6 className='card-text'>
                        {truncate(item.co, {
                          length: 100,
                          omission: '...',
                        })}
                      </h6>
                      <h6 className='cloudy'>
                        {truncate(item.kg, {
                          length: 100,
                          omission: '...',
                        })}
                      </h6> */}
                        <h6 className="card-text">Plantation Date</h6>
                        <h6 className="cloudy">
                          {moment(
                            dataValues && dataValues.plantationDate
                          ).format("ddd, MMM D YYYY")}
                        </h6>
                        <h6 className="card-text">
                          {truncate(item.plantedby, {
                            length: 100,
                            omission: "...",
                          })}
                        </h6>
                        <h6 className="cloudy">
                          {dataValues && dataValues.plantedBy}
                        </h6>
                        <h6 className="card-text">
                          {truncate(item.donatetree, {
                            length: 100,
                            omission: "...",
                          })}
                        </h6>
                        <span className="card-text">
                          {" "}
                          {dataValues && dataValues.species.speciesName}{" "}
                          &nbsp;&nbsp;
                          <OverlayTrigger placement="right" overlay={popover}>
                            <img
                              src={infoIcon}
                              alt="info"
                              className="hand-pointer icon-info"
                              width="18px"
                              height="18px"
                            />
                          </OverlayTrigger>
                        </span>
                      </div>
                    </div>
                  );
                })}

              {customcardData &&
                customcardData.map((item) => {
                  return (
                    <div
                      className="card custom-cards1 col-md-3 col-sm-6"
                      id="style-3"
                      key={item.id}
                    >
                      <div className="card-body">
                        <div className="intro-carousel mg-bottom20 mg-top10">
                          {dataValues && dataValues.imagesLog && (
                            <Carousel
                              arrows={true}
                              autoPlaySpeed={3000}
                              draggable
                              infinite
                              keyBoardControl
                              minimumTouchDrag={80}
                              partialVisible
                              renderButtonGroupOutside={true}
                              swipeable
                              responsive={responsive}
                              customButtonGroup={<ButtonGroup />}
                            >
                              {dataValues &&
                                dataValues.imagesLog &&
                                dataValues.imagesLog.map((imageitem) => {
                                  return (
                                    <div
                                      className="img-wrapper"
                                      key={imageitem?.id}
                                    >
                                      <img
                                        data-src={imageitem.imageUrl}
                                        className="lazyload"
                                        alt="path tree"
                                      />
                                    </div>
                                  );
                                })}
                            </Carousel>
                          )}
                        </div>
                        <div className="scrollsection" id="style-3">
                          <h5 className="card-title title-items1">
                            <span className="next-display card-text">
                              {truncate(projectData && projectData.name, {
                                length: 65,
                                omission: "...",
                              })}
                            </span>
                          </h5>

                          <h6 className="cloudy">{projectData.description}</h6>
                          <h6 className="card-text">Contact Number</h6>
                          <h6 className="cloudy">{projectData.phoneNumber}</h6>
                          <h6 className="card-text">
                            {truncate(item.trackid, {
                              length: 100,
                              omission: "...",
                            })}
                          </h6>

                          <h6 className="cloudy">
                            {truncate(dataValues && dataValues.trackingId, {
                              length: 100,
                              omission: "...",
                            })}
                          </h6>

                          {/*   <h6 className='card-text'>
                          {truncate(item.location, {
                            length: 100,
                            omission: '...',
                          })}
                        </h6>
                        <h6 className='cloudy'>
                          {truncate(dataValues && dataValues.city, {
                            length: 100,
                            omission: '...',
                          })}
                        </h6> */}
                          <h6 className="card-text">
                            {truncate(item.trees, {
                              length: 100,
                              omission: "...",
                            })}
                          </h6>
                          <h6 className="cloudy">
                            {truncate(
                              dataValues && dataValues.species.speciesName,
                              {
                                length: 100,
                                omission: "...",
                              }
                            )}
                          </h6>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      ) : null}
      <ToastContainer
        position="top-right"
        autoClose={3000}
        newestOnTop={false}
        hideProgressBar
        closeOnClick
        rtl={false}
      />
    </>
  );
}

// Mapinitiative.propTypes = {
//   data: PropTypes.objectOf(PropTypes.object()).isRequired,
//   donationData: PropTypes.objectOf(PropTypes.object()).isRequired,
// };
